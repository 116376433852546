<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>Statement for {{ customer.name }}</h1>
        <v-form @submit.prevent="getStatement">
          <v-row class="pt-8">
            <v-col class="col-4">
              <v-dialog
                ref="dialog"
                v-model="modal"
                persistent
                width="350px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <label class="pl-8">Date</label>
                  <v-text-field
                    v-model="formattedDate"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  range>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="modal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col class="col-3 pt-10">
              <v-btn type="sumbit">View Statement</v-btn>
              &nbsp;
            </v-col>
            <v-col v-if="statement.length > 0" class="col-2 pt-10">
                <v-btn @click="exportStatement">Export Statement</v-btn>
              &nbsp;
            </v-col>
            <v-col v-if="statement.length > 0" class="col-2 pt-10">
              <router-link
                v-if="formattedDate[0]"
                :to="`/invoices/statement-print/${customer.id}/${formattedDate[0]}/${formattedDate[1]}`">
                <v-btn type="sumbit">Print Statement</v-btn>
              </router-link>
              <router-link
                v-else
                :to="`/invoices/statement-print/${customer.id}`">
                <v-btn type="sumbit">Print Statement</v-btn>
              </router-link>
              &nbsp;
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <v-simple-table>
          <thead>
            <tr>
              <th>Invoice No</th>
              <th>Date</th>
              <th>Site Address</th>
              <th>Amount</th>
              <th>O/S</th>
              <th>Paid</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(line, index) in statement" :key="index">
              <td>{{ line.number }}</td>
              <td>{{ line.date | prettyDate }}</td>
              <td>{{ line.siteAddress }}</td>
              <td>{{ line.amount | priceInPounds | currency }}</td>
              <td>{{ line.outstanding | priceInPounds | currency }}</td>
              <td>{{ line.paid | priceInPounds | currency }}</td>
              <td>
                <router-link :to="`/invoices/view/${line.id}`">
                  <v-btn x-small>View</v-btn>
                </router-link>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'InvoicesStatement',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      customer: {},
      date: [],
      formattedDate: [],
      modal: false,
      statement: [],
    };
  },
  watch: {
    date(value) {
      this.formattedDate = [];
      for(let i = 0; value.length > i; i += 1) {
        const date = this.formatDate(value[i]);
        this.formattedDate.push(date);
      }
    },
  },
  methods: {
    getCustomer() {
      const customerId = this.$route.params.id;
      axios.get(`/customers/getSingle/${customerId}.json?token=${this.token}`)
        .then((response) => {
          this.customer = response.data.customer;
          this.getStatement();    
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportStatement() {
      const postData = {};
      postData.customerId = this.customer.id;
      postData.dates = this.formattedDate;
      axios.post(`/spreadsheets/customerStatement.json?token=${this.token}`, postData, {responseType:'blob'})
        .then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `customer_statement.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
    },
    getStatement() {
      const postData = {};
      postData.customerId = this.$route.params.id;
      postData.dates = this.formattedDate;
      axios.post(`/invoices/getStatement.json?token=${this.token}`, postData)
        .then((response) => {
          this.statement = response.data.statement;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatDate (date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
  },
  mounted() {
    this.getCustomer();
  },
};
</script>
